import React, { useState } from "react"
import StandardLayout from "../layouts/standard"
import coverImage from "../images/videos/Group 308.png"
import playBtn from "../images/videos/Group 300.svg"
import video1 from "../images/video1.mp4"
import video2 from "../images/video2.mp4"
import video3 from "../images/video3.mp4"

const allVideos = [
  {
    title: "#KuchNahiDikhega",
    description:
      "No possibility of tampering on this pitch! Hit a six with Stylam.",
    video: video3,
  },
  {
    title: "#KuchNahiDikhega",
    description:
      "We found your life partner & you couldn’t have asked for better!",
    video: video1,
  },
  {
    title: "#KuchNahiDikhega",
    description: "Is love really blind? Test it out for yourself!",
    video: video2,
  },
  {
    title: "",
    description:
      "PU+ LACQUER for Premium Surfaces by STYLAM I Product Manufacturing Video I Trueline Media",
    video: "https://videos-new-website.s3.ap-south-1.amazonaws.com/stylam.mov",
  },
]

export default function Videos(props) {
  const [selectedVideo, setSelectedVideo] = useState(allVideos[0])

  return (
    <StandardLayout title="Videos">
      <div className="cover-cont">
        <img src={coverImage} alt="" />
        <div>Videos</div>
      </div>
      <div className="catalog-head">Videos</div>

      <section className="">
        <div className="uk-container uk-margin">
          <div className="main-vid-cont">
            <div className="vid-text">
              {selectedVideo.title}
              <br />
              {selectedVideo.description}
            </div>
            <div className="vid-cont">
              <video
                src={selectedVideo.video}
                controls
                playsinline="false"
              ></video>
            </div>
          </div>

          <div className="more-vid-cont">
            <span className="more-vid-text">More Videos</span>
            <div className="next-prev-text">
              <span style={{ marginRight: "10px" }}>{"<< Prev"}</span>
              <span>{"Next >>"}</span>
            </div>
          </div>

          <div className="video-list">
            {allVideos.map((video, index) => (
              <div
                className="videontext"
                onClick={() => setSelectedVideo(allVideos[index])}
              >
                <div className="vid-play-cont">
                  <video src={video.video} playsinline="false"></video>
                  <img src={playBtn} alt="play button" />
                </div>
                <div className="vid-text2">
                  {video.title}
                  <br /> {video.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </StandardLayout>
  )
}
